<template>
    <b-container fluid class="bg-white">
        <b-row class="justify-content-center">
            <div class="text-center mb-5">
                <h2 style="color:#f6601a" class="my-4 mb-2">{{$t('404')}}</h2>
                <h4>{{$t('404_1')}}</h4>
            </div>
        </b-row>
    </b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>